<template>
  <div class="modal" style="display:block">

    <div class="modal-content">
        <div class="indice">
            <p v-html="item.message"></p>
            <img
                :src="item.image"
                :alt="item.label">
        </div>
    </div>

    <div class="modal-footer">
        <button v-if="!isAlreadyInDossier && item.readonly === undefined" type="button" class="btn btn-blue align-center" @click="addToIndice()"><span>Ajouter aux indices</span></button>

        <button v-else type="button" class="btn btn-blue align-left" @click="close()"><span>Retour</span></button>
    </div>
</div>
</template>

<script>
import jsonIndice from '@/data/indice_objects.json'
import { EventBus } from '@/core/event-bus.js';

export default {
  name: 'IndiceViewer',
  props : [
    'options'
  ],
  computed: {
    item() {
      let res = jsonIndice.find( r => {
          return r.label == this.options
      })
      return res
    },
    itemsInDossier() {
      return this.$store.state.dossiers.indices
    },
    isAlreadyInDossier() {
      return this.itemsInDossier.indexOf(this.options) !== -1
    }
  },
  methods : {
    close() {
      this.$emit('close')
    },
    addToIndice() {
      this.$store.commit("addToDossier", {dossier: 'indices', item: this.options})
      EventBus.$emit('ADD-INDICE');
      this.$emit('close')
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
